import { getCookie, removeCookie, setCookie } from '../../utility/Utils';

export type PackageModel = {
  id: string;
  name: string;
  description: string;
  price: number;
  vatPercent: number;
  net: number;
  leftTime: number;
  packageFeatures: {
    name: string;
  }[];
};

const usePackage = () => {
  const setPackage = (packageData: PackageModel) => {
    setCookie('packageData', JSON.stringify(packageData) || null);
  };

  const getPackage = () => {
    return JSON.parse(getCookie('packageData')) || null;
  };

  const isStandardPackage = () => {
    const packageData: PackageModel = getPackage();
    return packageData?.name === 'Standard' ? true : false;
  };

  const clearPackage = () => {
    removeCookie('packageData');
  };

  return {
    setPackage,
    getPackage,
    clearPackage,
    isStandardPackage,
  };
};

export default usePackage;
